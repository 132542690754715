<template>
    <div>
        <card-header title="New Answer" icon="fa-plus"/>

        <card-body>
            <answer-form :answer="newAnswer"/>
        </card-body>

        <card-footer class="pt-3 pb-3 has-text-right">
            <b-button type="is-primary" size="is-small" :disabled="!newAnswer.answer" @click="submit"><i class="fas fa-plus mr-3"></i>New Answer</b-button>
        </card-footer>
    </div>
</template>

<script>
    import CardHeader from '../../TIER/components/CardHeader';
    import CardContainer from '../../TIER/components/CardContainer';

    import {client as http} from '../../http_client';
    import CardFooter from "../../TIER/components/CardFooter";
    import CardList from "../../TIER/components/CardList";
    import SubheaderListItem from "../../TIER/components/SubheaderListItem";

    import CardBody from "../../TIER/components/CardBody";
    import ContainerListItem from "../../TIER/components/ContainerListItem";
    import AnswerForm from "@/cards/trips/AnswerForm";
    import QuestionListItem from "@/components/QuestionListItem";

    export default {
        name: "NewEvent",
        props: ['card', 'props'],
        components: {QuestionListItem, AnswerForm, ContainerListItem, CardBody, SubheaderListItem, CardList, CardFooter, CardContainer, CardHeader},
        data: function () {
            return {
                newAnswer: {},
                question: {}
            };
        },
        methods: {
            loadQuestion: function(force) {
                this.$emit('loading', true);
                http.get('/api/questions/' + this.props.questionId, {force}).then(response => {
                    this.question = response.data;
                    this.$emit('loading', false);
                });
            },
            submit() {
                this.$emit('loading', true);
                http.post('/api/answers/', {question_id: this.props.questionId, ...this.newAnswer}, {force: true}).then(response => {
                    this.$reloadCard('question', {questionId: this.props.questionId});
                    this.$saveAlert(false);
                    this.$closeCard(this.card);
                }).catch(err => {
                    console.log('An error occurred.');
                    this.$emit('loading', false);
                });
            }
        },
        mounted() {
            this.$saveAlert(true, 'Are you done creating this new answer? Unsaved changes will be lost.');
            // this.loadQuestion();
        }
    };
</script>
